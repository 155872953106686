import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const PostCard = ({ post, index, home }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    return (
        <article className={'post-card post '.concat(home && post.feature_image && index == 0 ? 'post-card-large' : '')}>
            {post.feature_image &&
                <Link to={url} className="post-card-image-link">
                    <img className="post-card-image"
                        srcSet={`${post.feature_image} 300w,
                                ${post.feature_image} 600w,
                                ${post.feature_image} 1000w,
                                ${post.feature_image} 2000w`}
                        sizes="100vw"
                        src={post.feature_image}
                        alt={post.title}
                        loading="lazy"
                    />
                </Link>
            }
            
            <div className="post-card-content">
                <Link to={url} className="post-card-content-link">
                    <header className="post-card-header">
                        {post.primary_tag && home && <div className="post-card-primary-tag"> {post.primary_tag.name} </div>}
                        <h2 className="post-card-title">{post.title}</h2>
                    </header>
                    <section className="post-card-excerpt">
                        <p>{post.excerpt}</p>
                    </section>
                </Link>

                <footer className="post-card-meta">
                    <ul className="author-list">
                        {post.primary_author && 
                            <li className="author-list-item">
                                { post.primary_author.profile_image ?
                                    <Link to={url} className="static-avatar">
                                        <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name} />
                                    </Link> :
                                    <Link to={url} className="static-avatar">
                                        <img className="static-avatar author-profile-image" src="/images/icons/avatar.svg" alt={post.primary_author.name}/>
                                    </Link>
                                }
                            </li>
                        }
                    </ul>
                    
                    <div className="post-card-byline-content">
                        <span>{post.primary_author.name}</span>
                        <span className="post-card-byline-date"><time dateTime={post.published_at}>{post.published_at_pretty}</time> <span className="bull">&bull;</span> {readingTime}</span>
                    </div>
                </footer>
            </div>
        </article>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
