import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql, withPrefix } from 'gatsby'
import { Facebook, Twitter, Rss } from './meta/Icon'

import { Navigation } from '.'
import config from '../../utils/siteConfig'

// Styles
import '../../styles/app.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null
    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = function() {
        setShowMenu(showMenu => !showMenu);
    }

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass + (!!showMenu ? ' gh-head-open' : '')} />
            </Helmet>

            <div className="viewport">
                <header id="gh-head" className={'gh-head '.concat( !!site.cover_image ? 'has-cover' : '')}>
                    <nav className="gh-head-inner inner gh-container">
 
                        <div className="gh-head-brand">
                            <Link className="gh-head-logo" to="/">
                                { site.logo ?
                                    <img src={site.logo} alt={site.title} />
                                :
                                    site.title
                                }
                            </Link>
                            <a className="gh-burger" role="button" href="javascript:void(0)" onClick={toggleMenu}>
                                <div className="gh-burger-box">
                                    <div className="gh-burger-inner"></div>
                                </div>
                            </a>
                        </div>
                        <div className="gh-head-menu">
                            <Navigation data={site.navigation} siteUrl={config.siteUrl} navclassName="site-nav-item" />
                        </div>
                        <div className="gh-head-actions">
                            <div className="gh-social">
                                { site.facebook && <a className="gh-social-facebook" href={ facebookUrl } title="Facebook" target="_blank" rel="noopener noreferrer"><Facebook /></a> }
                                { site.twitter && <a className="gh-social-twitter" href={ twitterUrl } title="Twitter" target="_blank" rel="noopener noreferrer"><Twitter /></a> }
                                <a className="site-nav-item" href={ `https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/` } target="_blank" rel="noopener noreferrer"><Rss /></a>
                            </div>
                        </div>
                    </nav>
                </header>
            
                <main>
                    {/* All the main content gets inserted here, index.js, post.js */}
                    { children }
                </main>

                {/* The global footer at the very bottom of the screen */}
                <footer className="site-footer outer">
                    <div className="inner">
                        <section className="copyright">
                        <Link to="/">{site.title}</Link> &copy; {(new Date().getFullYear())}</section>
                        <nav className="site-footer-nav">
                            <Navigation data={site.navigation} siteUrl={config.siteUrl} />
                        </nav>
                        <div><a href="#" target="_blank" rel="noopener">Powered by Gatsby</a></div>
                    </div>
                </footer>
                
            </div>
        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
